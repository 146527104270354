import { fixFormattedDateToISOString } from '../../../utils/dateFormatter';
import { getSchedule } from '../../../api/schedule.api';
import { SchedulingSectionStatus } from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { WidgetConfig } from '../../../types/shared-types';
import { ServiceType } from '@wix/bookings-uou-types';
import { ScheduleStatus } from '@wix/ambassador-services-catalog-server/types';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  isAppointmentService,
  isCourseService,
} from '@wix/bookings-calendar-catalog-viewer-mapper/dist/cjs/domain/service/service';

export async function getServiceSchedulingData({
  config,
  settings,
  httpClient,
  instance,
  locationId,
  isIncreaseServicePageTimeBudget,
  service,
}: {
  service?: Service;
  config?: WidgetConfig;
  settings: any;
  httpClient: any;
  instance: string;
  locationId?: string;
  isIncreaseServicePageTimeBudget: boolean;
}) {
  const isCourse = service
    ? isCourseService(service)
    : config?.serviceDto?.type === ServiceType.COURSE;
  const isAppointment = service
    ? isAppointmentService(service)
    : config?.serviceDto?.type === ServiceType.INDIVIDUAL;

  if (!isAppointment) {
    const schedule = service
      ? service.schedule
      : config?.SEO?.serviceResponse?.schedules?.find(
          (serviceSchedule) =>
            serviceSchedule.status === ScheduleStatus.CREATED,
        );
    const now = new Date();
    const from = isCourse
      ? fixFormattedDateToISOString(schedule?.firstSessionStart)
      : now.toISOString();
    const to = isCourse
      ? fixFormattedDateToISOString(schedule?.lastSessionEnd)
      : new Date(
          now.setDate(now.getDate() + settings.scheduleDays),
        ).toISOString();
    if (from && to) {
      const response = await httpClient.request({
        ...getSchedule([schedule?.id], from, to, locationId),
        headers: isIncreaseServicePageTimeBudget
          ? { 'x-time-budget': '10000' }
          : undefined,
      });
      return response.data;
    }
  }
  return { status: SchedulingSectionStatus.EMPTY };
}
